import React, { useCallback, useEffect, useState } from 'react'
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import { Page } from '../../common'
import { CDatePicker, CButton, CCard, CCardBody, CCardTitle, CCol, CRow } from '@coreui/react-pro'
import { SalesByDeliveryDate } from '../types'
import { useSalesByDeliveryDate } from '../store/reportsApi'
import CountUp from 'react-countup'
import moment from 'moment'

interface RevenueChartProps {
    data: SalesByDeliveryDate[]
}

function RevenueChart({ data }: RevenueChartProps) {
    return (
        <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="order_date" />
            <YAxis domain={[0, 1000000]} orientation={'right'} />
            <Tooltip />
            <Legend />
            <ReferenceLine y={500000} stroke="#d1a18a" strokeWidth={1} strokeDasharray="3 1" />
            <Bar dataKey={'order_total'} name={'Daglig'} fill="#acdbfc" />
            <Line
                type="monotone"
                name={'Totalt'}
                dataKey="running_order_total"
                stroke="#ffdf73"
                strokeWidth={5}
                activeDot={{ r: 8 }}
            />
        </ComposedChart>
    )
}

function OrderChart({ data }: RevenueChartProps) {
    return (
        <ComposedChart
            width={500}
            height={400}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="order_date" />
            <YAxis domain={[0, 5000]} orientation={'right'} />
            <Tooltip />
            <Legend />
            <Bar dataKey={'order_count'} name={'Ordre/dag'} fill="#000B85" />
            <Line
                type="monotone"
                name={'Totalt'}
                dataKey="running_order_count"
                stroke="#6CCAFF"
                strokeWidth={5}
                activeDot={{ r: 8 }}
            />
        </ComposedChart>
    )
}

export default function Dashboard() {
    const [muted, setMuted] = useState(true)

    const [lastRevenueMark, setLastRevenueMark] = useState(-1)
    const [date, setDate] = useState(moment().add(1, 'days').toDate())
    const { data, isSuccess } = useSalesByDeliveryDate(date)
    const getTotalRevenue = (data: SalesByDeliveryDate[]): number => {
        if (data.length == 0) return 0
        const sorted = [...data].sort((a, b) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
        return sorted[0].running_order_total
    }

    const getTotalOrders = (data: SalesByDeliveryDate[]): number => {
        if (data.length == 0) return 0
        const sorted = [...data].sort((a, b) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
        return sorted[0].running_order_count
    }

    const formatMoney = useCallback((amount: number) => {
        return (
            '💰' +
            new Intl.NumberFormat('nb-NO', {
                style: 'currency',
                currency: 'NOK',
            }).format(amount) +
            ' 💰'
        )
    }, [])

    const formatOrders = useCallback((amount: number) => {
        return amount + ' ordre'
    }, [])

    useEffect(() => {
        if (!data) return

        const revenue = getTotalRevenue(data)
        const currentRevenueMark = Math.floor(revenue / 50000)

        if (lastRevenueMark === -1) {
            setLastRevenueMark(currentRevenueMark)
            return
        }

        if (currentRevenueMark > lastRevenueMark) {
            console.log('chingching', { currentRevenueMark, lastRevenueMark })
            if (!muted) {
                new Audio('audio/chingching.m4a').play().catch((e) => console.error(e))
            }
            setLastRevenueMark(currentRevenueMark)
        }
    }, [data])

    return (
        <Page title={'Dashboard'}>
            <CDatePicker
                locale="no-NB"
                size="lg"
                placeholder={'Velg leveringsdato'}
                className={'mb-3'}
                date={date}
                onDateChange={(date: Date | null) => {
                    if (date) {
                        setDate(date)
                    }
                }}
            />
            {isSuccess && (
                <CCol>
                    <CRow>
                        <CCol>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>Omsetning</CCardTitle>
                                    <RevenueChart data={data} />
                                    <CountUp
                                        style={{ fontSize: '3.5rem', fontWeight: 'bold' }}
                                        end={getTotalRevenue(data)}
                                        preserveValue={true}
                                        decimalPlaces={2}
                                        decimals={2}
                                        formattingFn={formatMoney}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol>
                            <CCard>
                                <CCardBody>
                                    <CCardTitle>Ordre</CCardTitle>
                                    <OrderChart data={data} />
                                    <CountUp
                                        style={{ fontSize: '3.5rem', fontWeight: 'bold' }}
                                        end={getTotalOrders(data)}
                                        preserveValue={true}
                                        formattingFn={formatOrders}
                                    />
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>

                    <CButton className={'m-4'} color={'light'} onClick={() => setMuted(!muted)}>
                        {muted ? 'Unmute' : 'Mute'}
                    </CButton>
                </CCol>
            )}
        </Page>
    )
}
