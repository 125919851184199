import { CFormInputProps } from '@coreui/react-pro/dist/components/form/CFormInput'
import { CFormInput, CFormSelect, CFormTextarea } from '@coreui/react-pro'
import './Input.scss'
import { CFormSelectProps } from '@coreui/react-pro/src/components/form/CFormSelect'
import { CFormTextareaProps } from '@coreui/react-pro/src/components/form/CFormTextarea'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

interface InputProps extends CFormInputProps {
    label: string
}

export function Input(props: InputProps) {
    return (
        <div className={'input-wrapper'}>
            <CFormInput {...props} placeholder={props.label} floatingLabel={props.label} />
        </div>
    )
}

interface SelectProps extends CFormSelectProps {
    label: string
}

export function Select(props: SelectProps) {
    return (
        <div className={'input-wrapper'}>
            <CFormSelect {...props} placeholder={props.label} floatingLabel={props.label} />
        </div>
    )
}

interface TextAreaProps extends CFormTextareaProps {
    label: string
}

export function TextArea(props: TextAreaProps) {
    return (
        <div className={'input-wrapper'}>
            <CFormTextarea {...props} placeholder={props.label} floatingLabel={props.label} />
        </div>
    )
}

interface SearchParamInputProps {
    onChange: (value: string) => void
    parameter: string
}

export const SearchParamInput = ({ onChange, parameter }: SearchParamInputProps) => {
    const history = useHistory()
    const [_value, setValue] = useState(new URLSearchParams(useLocation().search).get(parameter) ?? '')

    useEffect(() => {
        console.log('Triggering SearchParamInput for ' + parameter)
        setValue(_value)
        onChange(_value + '')
    }, [])

    const updateSearchParams = (key: string, value?: string) => {
        const searchParams = new URLSearchParams(window.location.search)
        if (key && value) {
            searchParams.set(key, value)
        } else {
            searchParams.delete(key)
        }
        history.replace({
            search: searchParams.toString(),
        })
    }

    return (
        <CFormInput
            size="sm"
            value={_value}
            onChange={(event) => {
                updateSearchParams(parameter, event.target.value)
                setValue(event.target.value)
                onChange(event.target.value)
            }}
        />
    )
}
