import { Page } from '../../common'
import { CButton, CCol, CRow, CSpinner } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import React from 'react'
import { purchaseOrderApi } from '../store/purchaseOrderApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { useHistory } from 'react-router-dom'
import { PurchaseOrderTable } from '../components/PurchaseOrderTable'

export default function PurchaseOrderListPage() {
    const { data: purchaseOrders, isLoading, isSuccess, isError, error } = purchaseOrderApi.useGetPurchaseOrdersQuery()
    const history = useHistory()

    const handleCreatePurchaseOrder = () => {
        history.push('/innkjøp/ny')
    }

    return (
        <Page title={'Innkjøpsordre (for WMS)'}>
            <CRow>
                <CCol
                    sm={12}
                    md={6}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <p>Her kan du se og opprette innkjøpsordre</p>
                </CCol>
                <CCol
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CButton
                        className={'mb-3 mr-1'}
                        color={'primary'}
                        onClick={handleCreatePurchaseOrder}
                        disabled={isLoading}
                    >
                        <CIcon className={'mr-1'} icon={cilPlus} size="sm" />
                        Opprett innkjøpsordre
                    </CButton>
                </CCol>
            </CRow>
            {isError && <ErrorMessage error={error} />}
            {isLoading && <CSpinner />}
            {isSuccess && purchaseOrders?.length && <PurchaseOrderTable purchaseOrders={purchaseOrders} />}
        </Page>
    )
}
