import { CForm, CFormInput, CInputGroup, CSpinner } from '@coreui/react-pro'
import { useSearchQuery } from '../store/orderAdminApi'
import { useState } from 'react'
import OrderSearchResultTable from '../components/OrderSearchResultTable'
import { Page } from '../../common'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'

function OrderSearchPage() {
    const [query, setQuery] = useState('')
    const {
        data: searchResult,
        isLoading,
        isError,
        error,
        isSuccess,
    } = useSearchQuery(query, {
        pollingInterval: 15000,
    })

    return (
        <Page title="Ordresøk">
            <CForm>
                <CInputGroup className="mb-3">
                    <CFormInput
                        name={'search_term'}
                        placeholder="Ordre ID"
                        aria-label="Ordre ID"
                        aria-describedby="search_button"
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </CInputGroup>
            </CForm>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && searchResult && (
                <>
                    <p>Viser {searchResult.hits} treff</p>
                    <OrderSearchResultTable orders={searchResult.orders} />
                </>
            )}
        </Page>
    )
}

export default OrderSearchPage
