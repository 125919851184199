import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Order, OrderSearchResult } from '../types'

export const orderAdminApi = createApi({
    reducerPath: 'orderAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getOrder: builder.query<Order, string>({
            query: (orderId) => ({
                url: `/admin/order/${orderId}`,
                method: 'GET',
            }),
        }),
        search: builder.query<OrderSearchResult, string>({
            query: (query) => ({
                url: `/admin/order/search?query=${query}`,
                method: 'GET',
            }),
        }),
        getOrders: builder.query<Order[], void>({
            query: () => ({
                url: `/admin/order`,
                method: 'GET',
            }),
        }),
        exportOrderToWms: builder.mutation<void, string>({
            query: (orderId) => ({
                url: `/admin/order/${orderId}/wms/export`,
                method: 'POST',
            }),
        }),
    }),
})

export const { useGetOrderQuery, useGetOrdersQuery, useSearchQuery, useExportOrderToWmsMutation } = orderAdminApi
