import { inventoryApi } from '../store/inventoryApi'
import { CButton, CCollapse } from '@coreui/react-pro'
import { useState } from 'react'

interface InventoryHistoryProps {
    id: string
}

export default function InventoryHistory({ id }: InventoryHistoryProps) {
    const { data, isLoading, isSuccess } = inventoryApi.useGetInventoryHistoryQuery(id)
    const [showHistory, setShowHistory] = useState(false)
    return (
        <>
            <CButton size={'sm'} onClick={() => setShowHistory(!showHistory)}>
                Vis/skjul historikk
            </CButton>
            <CCollapse visible={showHistory}>
                {isLoading && <p>Loading...</p>}
                {isSuccess && (
                    <div>
                        {data.map((ih) => (
                            <pre style={{ maxWidth: '100%', whiteSpace: 'pre-wrap' }} key={ih.time}>
                                {ih.data}
                            </pre>
                        ))}{' '}
                    </div>
                )}
            </CCollapse>
        </>
    )
}
