import { CForm, CFormSelect, CInputGroup, CInputGroupText } from '@coreui/react-pro'
import React from 'react'
import { inventoryApi } from '../store/inventoryApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface Props {
    productId: string
    onSuccess?: () => void
}

export function CreateInventoryManagementForm(props: Props) {
    const dispatch = useDispatch()
    const [setInventoryType] = inventoryApi.useAddInventoryManagementMutation()
    const { data, isLoading } = inventoryApi.useGetInventoryQuery(props.productId)
    const handleSetInventoryType = (productId: string, type: 'STOCK' | 'PRODUCE') => {
        executeMutation(
            setInventoryType({ productId, type }),
            dispatch,
            'Lagertype satt',
            'Kunne ikke sette til lagertype',
            () => {},
            () => {
                props.onSuccess && props.onSuccess()
            }
        )
    }
    return (
        <CForm className="row g-3 align-items-center">
            <CInputGroup className="mb-3">
                <CInputGroupText style={{ width: '20%' }}>Lagertype</CInputGroupText>
                <CFormSelect
                    disabled={isLoading || data?.type !== undefined}
                    id="type"
                    value={data?.type}
                    onChange={(e) => {
                        handleSetInventoryType(props.productId, e.currentTarget.value as 'STOCK' | 'PRODUCE')
                    }}
                >
                    <option value={''}>Velg type</option>
                    <option value={'STOCK'}>Lagerstyring</option>
                    <option value={'PRODUCE'}>Kapasitetstyring</option>
                </CFormSelect>
            </CInputGroup>
        </CForm>
    )
}
