import { PurchaseOrder } from '../types'
import { useDispatch } from 'react-redux'
import { useUpdatePurchaseOrderMutation } from '../store/purchaseOrderApi'
import { executeMutation } from '../../common/utils/apiUtils'
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputGroup,
    CInputGroupText,
} from '@coreui/react-pro'
import { purchaseOrderStatusColor, purchaseOrderStatusText } from '../util'

interface PurchaseOrderStatusDropdown {
    purchaseOrder: PurchaseOrder
}

export default function PurchaseOrderStatusDropdown({ purchaseOrder }: PurchaseOrderStatusDropdown) {
    const dispatch = useDispatch()
    const [updatePurchaseOrder] = useUpdatePurchaseOrderMutation()

    const handleSetOrderToOpen = () => {
        executeMutation(
            updatePurchaseOrder({ ...purchaseOrder, status: 'OPEN' }),
            dispatch,
            'Ordre åpnet',
            'Kunne ikke åpne ordre'
        )
    }

    const handleSetOrderToCancelled = () => {
        executeMutation(
            updatePurchaseOrder({ ...purchaseOrder, status: 'CANCELLED' }),
            dispatch,
            'Ordre kansellert',
            'Kunne ikke kansellere ordre'
        )
    }

    const handleSetOrderToUnknown = () => {
        executeMutation(
            updatePurchaseOrder({ ...purchaseOrder, status: 'UNKNOWN' }),
            dispatch,
            'Ordrestatus satt til ukjent',
            'Kunne ikke sette ordrestatus til ukjent'
        )
    }

    return (
        <CInputGroup>
            <CInputGroupText>Status</CInputGroupText>
            <CDropdown variant={'input-group'}>
                <CDropdownToggle
                    style={{ '--cui-elevation-base-color': 'transparent' }}
                    color={purchaseOrderStatusColor(purchaseOrder.status)}
                    disabled={purchaseOrder.status === 'CANCELLED' || purchaseOrder.status === 'RECEIVED'}
                >
                    {purchaseOrderStatusText(purchaseOrder.status)}
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem onClick={handleSetOrderToOpen}>Åpen</CDropdownItem>
                    <CDropdownItem onClick={handleSetOrderToCancelled}>Kansellert</CDropdownItem>
                    <CDropdownItem onClick={handleSetOrderToUnknown}>Ukjent</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </CInputGroup>
    )
}
