import { Region } from '../../types'
import style from './RegionSettings.module.scss'
import { FeatureToggle } from '../../../feature/components/FeatureToggle'
import {
    CMultiSelect,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import { regionAdminApi } from '../../store/regionAdminApi'
import { executeMutation } from '../../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import { usePackageLocations } from '../../../packagelocation/store/packageLocationApi'

interface RegionSettingsProps {
    region: Region
}

const BITLOG_WMS_FEATURE = 'BITLOG_WMS'

export function RegionSettings({ region }: RegionSettingsProps) {
    const [setRegionFeature] = regionAdminApi.useSetRegionFeatureMutation()
    const [setDefaultPackageLocation] = regionAdminApi.useSetDefaultPackageLocationMutation()
    const packageLocations = usePackageLocations()
    const dispatch = useDispatch()
    const { data } = regionAdminApi.useGetRegionFeatureQuery({
        regionId: region.id,
        feature: BITLOG_WMS_FEATURE,
    })

    const handleToggle = (feature: string) => {
        executeMutation(
            setRegionFeature({ regionId: region.id, feature, enabled: !data?.enabled }),
            dispatch,
            'Innstillingen ble endret',
            'Kunne ikke endre innstillingen'
        )
    }

    const handleSetDefaultPackageLocation = (packageLocationName: string) => {
        console.log(packageLocationName)
        executeMutation(
            setDefaultPackageLocation({ regionId: region.id, packageLocationName }),
            dispatch,
            `Standard pakksted ble satt til  ${packageLocationName} for ${region.name}`,
            `Kunne ikke endre standard pakksted for ${region.name} til ${packageLocationName}`
        )
    }

    return (
        <div>
            <h2 className={style.heading}>Regionsinnstillinger</h2>

            <CTable align="middle" borderless className={style.table}>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Innstilling</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    <FeatureToggle
                        feature={{
                            enabled: !!data?.enabled,
                            feature: 'test',
                            title: 'Bitlog WMS for ' + region.name,
                        }}
                        onToggle={() => handleToggle(BITLOG_WMS_FEATURE)}
                    />
                    <CTableRow>
                        <CTableDataCell>Standard pakksted</CTableDataCell>
                        <CTableDataCell>
                            <CMultiSelect
                                multiple={false}
                                placeholder={'Velg pakksted...'}
                                onChange={(e) => {
                                    handleSetDefaultPackageLocation(e[0].text as string)
                                }}
                                defaultValue={region.defaultPackageLocation}
                                options={packageLocations.map((pl) => {
                                    return { value: pl.id, text: pl.name }
                                })}
                            />
                        </CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable>
        </div>
    )
}
