import { BaseQueryFn, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { Dispatch } from 'redux'
import { apiUrl } from '../../config'
import { addToast } from '../store/commonSlice'
import { ErrorResponse } from '../types'

export function getAuthorizedBaseQuery() {
    return getBaseQuery(true)
}

export function getUnauthorizedBaseQuery() {
    return getBaseQuery(false)
}

export function executeMutation(
    mutationPromise: Promise<any>,
    dispatch: Dispatch,
    successMessage: string,
    errorMessage: string,
    finallyFunc?: () => void,
    successFunc?: () => void,
    errorFunc?: () => void
) {
    return mutationPromise
        .then((result) => {
            if ('error' in result) {
                dispatch(
                    addToast({
                        message: errorMessage,
                        color: 'danger',
                    })
                )
                if (errorFunc) {
                    errorFunc()
                }
            } else {
                dispatch(
                    addToast({
                        message: successMessage,
                        color: 'success',
                    })
                )
                if (successFunc) {
                    successFunc()
                }
            }
        })
        .catch(() => {
            dispatch(
                addToast({
                    message: errorMessage,
                    color: 'danger',
                })
            )
            if (errorFunc) {
                errorFunc()
            }
        })
        .finally(() => {
            if (finallyFunc) {
                finallyFunc()
            }
        })
}

function getBaseQuery(useAuth: boolean) {
    return fetchBaseQuery({
        baseUrl: apiUrl,
        prepareHeaders: (headers) => {
            headers.set('accept', 'application/json')
            if (useAuth) {
                const token = localStorage.getItem('token')
                if (token) {
                    headers.set('authorization', `Bearer ${token}`)
                }
            }
            return headers
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, ErrorResponse, Record<string, unknown>>
}
