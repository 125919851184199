import css from './ProgressBar.module.scss'

interface ProgressBarProps {
    progress: number
    type: ProgressBarType
    text: string
}

export enum ProgressBarType {
    Warning,
    Full,
    Disabled,
    Default,
}

function ProgressBar({ progress, type = ProgressBarType.Default, text = '' }: ProgressBarProps) {
    return (
        <div className={css.progressBar} data-text={text}>
            <span style={{ width: progress + '%' }} data-type={ProgressBarType[type]} />
        </div>
    )
}

export default ProgressBar
