import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { InventoryHistoryEntry } from '../types'

export interface Quantities {
    capacity: number
    reserved: number
}

export interface WarehouseAvailability {
    type: 'STOCK'
    data?: Record<
        RegionId,
        {
            availability: {
                stock: number
                reserved: Record<string, number>
            }
        }
    >
}

export interface ProduceAvailability {
    type: 'PRODUCE'
    data?: Record<RegionId, { availability: Record<string, Quantities> }>
}

export interface InventoryProduct {
    id: string
    title: string
    sku: string
    listImageUrl: string
    productId: string
    externalProductId: string
}

export type Availability = WarehouseAvailability | ProduceAvailability
export type ProductAvailability = InventoryProduct & Availability
type RegionId = number

export const inventoryApi = createApi({
    reducerPath: 'inventory',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['inventory'],
    endpoints: (builder) => ({
        list: builder.query<ProductAvailability[], void>({
            query: () => ({
                url: `/admin/inventory`,
                method: 'GET',
            }),
            providesTags: ['inventory'],
        }),

        addInventoryManagement: builder.mutation<void, { productId: string; type: 'STOCK' | 'PRODUCE' }>({
            query: (request) => ({
                url: `/admin/inventory`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['inventory'],
        }),

        deleteInventoryManagement: builder.mutation<void, { id: string }>({
            query: (request) => ({
                url: `/admin/inventory/${request.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['inventory'],
        }),

        getInventory: builder.query<Availability, string>({
            query: (productId) => ({
                url: `/admin/inventory?productId=${productId}`,
                method: 'GET',
            }),
            transformResponse: (response: Availability[]) => response[0],
            providesTags: (_result, _err, productId) => [{ type: 'inventory', productId }],
        }),

        getInventoryHistory: builder.query<InventoryHistoryEntry[], string>({
            query: (inventoryId) => ({
                url: `/admin/inventory/${inventoryId}/history`,
                method: 'GET',
            }),
        }),

        syncInventory: builder.mutation<any, void>({
            query: () => ({
                url: `/admin/inventory/sync`,
                method: 'POST',
            }),
            invalidatesTags: ['inventory'],
        }),
    }),
})
