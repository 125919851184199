import { Page } from '../../common'
import { CButton, CCol, CRow, CSpinner } from '@coreui/react-pro'
import {
    useExportProductMutation,
    useExportProductsMutation,
    useGetProductsQuery,
    useSyncProductsMutation,
} from '../store/productApi'
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'
import ProductTable from '../components/ProductTable'
import authenticatedClient from '../../services/axios'
import { apiUrl } from '../../config'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload, cilTransfer, cilVerticalAlignTop } from '@coreui/icons'

export default function ProductRegistryListPage() {
    const dispatch = useDispatch()
    const { data: products, isSuccess, isLoading, isError, error } = useGetProductsQuery()
    const [syncProducts, { isLoading: isUpdating }] = useSyncProductsMutation()
    const [exportProducts, { isLoading: isExporting }] = useExportProductsMutation()
    const handleSyncProducts = () => {
        executeMutation(
            syncProducts(),
            dispatch,
            'Produktregisteret har startet oppdatering',
            'Kunne ikke oppdatere produktregisteret',
            () => {}
        )
    }
    const handleExportProducts = () => {
        executeMutation(
            exportProducts(),
            dispatch,
            'Startet eksport til WMS',
            'Kunne ikke eksportere til WMS',
            () => {}
        )
    }

    const downloadFile = () =>
        authenticatedClient
            .get(`${apiUrl}/admin/product-registry`, {
                headers: {
                    Accept: 'text/csv',
                    'Content-Type': 'text/csv',
                },
            })
            .then(({ data }) => {
                const blob = new Blob([data])
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.setAttribute('href', url)
                anchor.setAttribute('download', `produkt-register.csv`)
                anchor.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => console.error(error.response.data))

    return (
        <Page
            title={'Produktregister'}
            preamble={'Her finner du oversikt over alle produkter som er registrert i systemet'}
        >
            <CRow>
                <CCol
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <CButton
                        className={'mb-3 mr-1'}
                        color={'primary'}
                        onClick={() => {
                            handleSyncProducts()
                        }}
                        disabled={isUpdating}
                    >
                        <CIcon className={'mr-1'} icon={cilTransfer} size="sm" />
                        Synkroniser med Craft
                    </CButton>
                    <CButton
                        className={'mb-3 mr-1'}
                        color={'primary'}
                        onClick={() => {
                            handleExportProducts()
                        }}
                        disabled={isExporting}
                    >
                        <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
                        Eksporter til WMS
                    </CButton>
                    <CButton color={'secondary'} className={'mb-3'} onClick={downloadFile}>
                        <CIcon className={'mr-1'} icon={cilCloudDownload} size="sm" />
                        Last ned som CSV
                    </CButton>
                </CCol>
            </CRow>
            {isLoading && <CSpinner />}
            {isError && <ErrorMessage error={error} />}
            {isSuccess && (
                <>
                    <ProductTable products={products} />
                </>
            )}
        </Page>
    )
}
