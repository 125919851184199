import { CFormSwitch } from '@coreui/react-pro'
import { usePublishToRegionMutation, useUnpublishFromRegionMutation } from '../store/productApi'
import { useEffect } from 'react'
import { addToast } from '../../common/store/commonSlice'
import { useDispatch } from 'react-redux'
import { useRegions } from '../../region/store/regionApi'

interface PublishToRegionSelectProps {
    productId: string
    activeRegionIds: number[]
}

export default function PublishToRegionSelect({ productId, activeRegionIds }: PublishToRegionSelectProps) {
    const regions = useRegions()
    const dispatch = useDispatch()
    const [publishToRegion, publishToRegionResult] = usePublishToRegionMutation()
    const [unpublishFromRegion, unpublishFromRegionResult] = useUnpublishFromRegionMutation()

    useEffect(() => {
        if (publishToRegionResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produkt lagt til i region',
                    color: 'success',
                })
            )
        }
        if (publishToRegionResult.isError) {
            dispatch(
                addToast({
                    message: 'Kunne ikke legge produkt i region',
                    color: 'danger',
                })
            )
        }
    }, [publishToRegionResult])

    useEffect(() => {
        if (unpublishFromRegionResult.isSuccess) {
            dispatch(
                addToast({
                    message: 'Produkt fjernet fra region',
                    color: 'success',
                })
            )
        }
        if (unpublishFromRegionResult.isError) {
            dispatch(
                addToast({
                    message: 'Kunne ikke fjerne produkt fra region',
                    color: 'danger',
                })
            )
        }
    }, [unpublishFromRegionResult])

    return (
        <>
            {regions.map((region) => {
                return (
                    <CFormSwitch
                        key={region.id}
                        style={
                            {
                                '--cui-form-check-input-checked-bg-color': 'var(--cui-success)',
                                '--cui-form-check-input-checked-border-color': 'var(--cui-success)',
                            } as React.CSSProperties
                        }
                        color={'success'}
                        size={'xl'}
                        className={'mb-3'}
                        label={region.name}
                        defaultChecked={activeRegionIds.includes(region.id)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                publishToRegion({ regionId: region.id, productId })
                            } else {
                                unpublishFromRegion({ regionId: region.id, productId })
                            }
                        }}
                    />
                )
            })}
        </>
    )
}
