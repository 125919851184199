import { CAlert, CButton, CCard, CCardBody, CCardText, CCardTitle, CCol, CRow, CSpinner } from '@coreui/react-pro'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import { useGetPaymentByOrderIdQuery, useSendReceiptMutation } from '../store/paymentAdminApi'
import PaymentHistoryTable from './PaymentHistoryTable'
import PaymentInfoTable from './PaymentInfoTable'
import PaymentStatusBadge from './PaymentStatusBadge'

export interface PaymentCardProps {
    orderId: string
}

function PaymentDetailsCard({ orderId }: PaymentCardProps) {
    const userCanEdit = userHasSomePermissions(useAuthenticatedUser(), 'orders.write')
    const { data: payment, isLoading, isError, error, isSuccess } = useGetPaymentByOrderIdQuery(orderId)
    const [sendReceipt, { isLoading: receiptLoading }] = useSendReceiptMutation()

    return (
        <CCard>
            <CCardBody>
                <CCardTitle>
                    <CRow>
                        <CCol>Betaling {payment && <>#{payment.payment.paymentId}</>}</CCol>
                        <CCol sm={5}>{payment && <PaymentStatusBadge paymentStatus={payment?.payment.status} />}</CCol>
                    </CRow>
                </CCardTitle>
                <CCardText>
                    {isLoading && <CSpinner />}
                    {isError && (
                        <CAlert color={'danger'}>
                            Det har skjedd en feil <br /> {JSON.stringify(error)}
                        </CAlert>
                    )}
                    {isSuccess && payment && (
                        <>
                            <PaymentInfoTable payment={payment.payment} />
                            <PaymentHistoryTable events={payment.paymentEventLog} />
                        </>
                    )}
                </CCardText>
                {payment && (
                    <CButton
                        onClick={() => sendReceipt(payment.payment.paymentId)}
                        disabled={!userCanEdit || receiptLoading}
                    >
                        Send kvittering
                    </CButton>
                )}
            </CCardBody>
        </CCard>
    )
}

export default PaymentDetailsCard
