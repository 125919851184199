import { CCardBody } from '@coreui/react-pro'
import { RegisteredProduct } from '../types'
import Card from '../../common/components/Card'
import { CreateInventoryManagementForm } from '../../inventory/components/CreateInventoryManagementForm'
import CardHeader from '../../common/components/CardHeader'
import React from 'react'
import { cilHome } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { inventoryApi, ProduceAvailability } from '../../inventory/store/inventoryApi'
import { regionApi } from '../../region/store/regionApi'
import { StockProductAvailabilities } from '../../inventory/components/StockProductAvailabilities'
import { Region } from '../../region/types'
import { ProduceProductAvailabilities } from '../../inventory/components/ProduceProductAvailabilities'

interface ProductInventoryCardProps {
    product: RegisteredProduct
}

export default function ProductInventoryCard({ product }: ProductInventoryCardProps) {
    const { data: availability } = inventoryApi.useGetInventoryQuery(product.id)
    const { data: enabledRegions } = regionApi.useGetRegionsQuery()

    const regionMap =
        enabledRegions?.reduce((acc, region) => {
            acc[region.id] = region
            return acc
        }, {} as Record<string, Region>) || {}

    const availableRegions = Object.keys(availability?.data || {}).map((key) => regionMap[key])

    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilHome} size={'xl'} className={'mr-1'} />
                Lager
            </CardHeader>
            <CCardBody>
                <p style={{ color: 'var(--cui-gray)' }}>Sett ønsket lagertype for produktet</p>
                <CreateInventoryManagementForm productId={product.id} />
                {availability?.type === 'STOCK' && (
                    <StockProductAvailabilities item={availability} regions={availableRegions} />
                )}
                {availability?.type === 'PRODUCE' && (
                    <ProduceProductAvailabilities
                        item={produceAvailabilityForNextDays(availability, 3)}
                        regions={availableRegions}
                    />
                )}
            </CCardBody>
        </Card>
    )
}

const produceAvailabilityForNextDays = (availability: ProduceAvailability, days: number): ProduceAvailability => {
    if (!availability.data) return availability
    const daysInMilliseconds = days * 24 * 60 * 60 * 1000

    return {
        ...availability,
        data: Object.fromEntries(
            Object.entries(availability.data).map(([regionId, regionData]) => {
                return [
                    regionId,
                    {
                        ...regionData,
                        availabilities: Object.fromEntries(
                            Object.entries(regionData.availability).filter(([dateString]) => {
                                const date = new Date(dateString)
                                const now = new Date()
                                return date >= now && date <= new Date(now.getTime() + daysInMilliseconds)
                            })
                        ),
                    },
                ]
            })
        ),
    }
}
