import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Capacities } from '../../common/types'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { DistributionCompany } from '../types'

interface CreateDistributionCompany {
    name: string
    regionId: number
    externalId: number
    orderDeadline: string
}

interface UpdateDistributionCompanyOrderDeadline {
    id: number
    orderDeadline: string
}

interface UpdateStandardCapacity {
    companyId: number
    dayOfWeek: number
    capacity: number
}

export const distributionApi = createApi({
    reducerPath: 'distribution',
    tagTypes: ['DistributionCompany', 'DistributionCapacity'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getDistributionCompanies: builder.query<DistributionCompany[], void>({
            query: () => ({
                url: '/admin/distribution/distribution-companies',
                method: 'GET',
            }),
            providesTags: ['DistributionCompany'],
        }),
        createDistributionCompany: builder.mutation<void, CreateDistributionCompany>({
            query: (data) => ({
                url: '/admin/distribution/distribution-company',
                method: 'POST',
                body: {
                    name: data.name,
                    regionId: data.regionId,
                    externalId: data.externalId,
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        updateDistributionCompany: builder.mutation<void, DistributionCompany>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.id}`,
                method: 'PUT',
                body: {
                    name: data.name,
                    regionId: data.regionId,
                    externalId: data.externalId,
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        updateDistributionCompanyOrderDeadline: builder.mutation<void, UpdateDistributionCompanyOrderDeadline>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.id}/order-deadline`,
                method: 'PUT',
                body: {
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        deleteDistributionCompany: builder.mutation<void, number>({
            query: (id) => ({
                url: `/admin/distribution/distribution-company/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        getDistributionCapacities: builder.query<Capacities[], void>({
            query: () => ({
                url: '/admin/distribution/distribution-companies/capacity/default',
                method: 'GET',
            }),
            providesTags: ['DistributionCapacity'],
        }),
        updateStandardCapacity: builder.mutation<void, UpdateStandardCapacity>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.companyId}/capacity/default/${data.dayOfWeek}`,
                method: 'PUT',
                body: {
                    capacity: data.capacity,
                },
            }),
            invalidatesTags: ['DistributionCapacity'],
        }),
    }),
})

export const useDistributionCompanies = () => distributionApi.useGetDistributionCompaniesQuery().data || []
export const useDistributionCapacities = () => distributionApi.useGetDistributionCapacitiesQuery().data || []
export const {
    useCreateDistributionCompanyMutation,
    useUpdateDistributionCompanyMutation,
    useUpdateDistributionCompanyOrderDeadlineMutation,
    useDeleteDistributionCompanyMutation,
    useUpdateStandardCapacityMutation,
} = distributionApi
