import { CButton } from '@coreui/react-pro'
import format from 'date-fns/format'
import { useState } from 'react'
import { DateChangeEvent, DatePicker, Page } from '../../common'
import { apiUrl } from '../../config'
import authenticatedClient from '../../services/axios'
import css from './common.module.scss'

function SalesReportPage() {
    const [selectedDates, setSelectedDates] = useState<DateChangeEvent>({ startDate: new Date(), endDate: new Date() })

    const downloadFile = () =>
        authenticatedClient
            .get(
                `${apiUrl}/reports/shipped-count-per-product-item?fromDate=${format(
                    selectedDates.startDate,
                    'yyyy-MM-dd'
                )}&toDate=${format(selectedDates.endDate, 'yyyy-MM-dd')}`
            )
            .then(({ data }) => {
                const blob = new Blob([data])
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.setAttribute('href', url)
                anchor.setAttribute(
                    'download',
                    `salgstall_${format(selectedDates.startDate, 'yyyy-MM-dd')}-${format(
                        selectedDates.endDate,
                        'yyyy-MM-dd'
                    )}.csv`
                )
                anchor.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => alert(error.response.data))

    const handleDateChange = (selectedDates: DateChangeEvent) => {
        setSelectedDates(selectedDates)
    }

    return (
        <Page
            title="Salgstall"
            preamble="Denne rapporten inneholder antall solgte produkter av hver type (fra bookede B2C-ordre) til valgt leveringsdag."
        >
            <div className={css.datePickerWrapper}>
                <h2>Velg periode</h2>
                <DatePicker onChange={handleDateChange} />
                <div>
                    <CButton onClick={downloadFile}>Last ned CSV</CButton>
                </div>
            </div>
        </Page>
    )
}

export default SalesReportPage
