import { createApi } from '@reduxjs/toolkit/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { PaymentWithHistory } from '../types'

export const paymentAdminApi = createApi({
    reducerPath: 'paymentAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getPaymentByOrderId: builder.query<PaymentWithHistory, string>({
            query: (orderId) => ({
                url: `/admin/payment/${orderId}`,
                method: 'GET',
            }),
        }),
        sendReceipt: builder.mutation<undefined, string>({
            query: (paymentId) => ({
                url: `/admin/payment/${paymentId}/receipt`,
                method: 'POST',
            }),
        }),
    }),
})

export const { useGetPaymentByOrderIdQuery, useSendReceiptMutation } = paymentAdminApi
