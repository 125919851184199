import { Page } from '../../common'
import { inventoryApi, ProductAvailability } from '../store/inventoryApi'
import {
    CBadge,
    CButton,
    CCardBody,
    CCol,
    CCollapse,
    CLoadingButton,
    CMultiSelect,
    CRow,
    CSmartTable,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { regionApi } from '../../region/store/regionApi'
import { Availabilities } from '../components/Availabilities'
import Card from '../../common/components/Card'
import { useGetProductsQuery } from '../../product/store/productApi'
import InventoryProductModal from '../components/InventoryProductsModal'
import CIcon from '@coreui/icons-react'
import { cilImage, cilPlus, cilTransfer } from '@coreui/icons'
import InventoryHistory from '../components/InventoryHistory'

export default function InventoryPage() {
    const { data: enabledRegions } = regionApi.useGetRegionsQuery()
    const { data: productRegistry } = useGetProductsQuery()
    const [createInventoryVisible, setCreateInventoryVisible] = useState(false)

    const { data: inventory, isLoading, refetch: refetchInventory } = inventoryApi.useListQuery()
    const [syncInventory, status] = inventoryApi.useSyncInventoryMutation()
    const [details, setDetails] = useState<string[]>([])

    const toggleDetails = (externalProductId: string) => {
        const newDetails = details.includes(externalProductId)
            ? details.filter((item) => item !== externalProductId)
            : [...details, externalProductId]
        setDetails(newDetails)
    }

    return (
        <Page title={'Lager'} preamble={'Her kan du styre lagerbeholdningen'}>
            <InventoryProductModal
                products={
                    productRegistry?.filter(
                        (p) => !inventory?.find((i) => i.externalProductId == p.externalProductId)
                    ) || []
                }
                onSetInventoryType={() => {
                    setTimeout(() => {
                        refetchInventory()
                    }, 100)
                }}
                visible={createInventoryVisible}
                onClose={() => {
                    setCreateInventoryVisible(false)
                }}
            />
            <Card>
                <CCardBody>
                    <CRow className={'mb-3'}>
                        <CCol className={'d-flex gap-2 justify-content-end'}>
                            <CLoadingButton
                                className={''}
                                loading={status.isLoading}
                                disabledOnLoading
                                onClick={() => {
                                    syncInventory()
                                }}
                            >
                                <CIcon className={'mr-1'} icon={cilTransfer} size="sm" />
                                Synkroniser
                            </CLoadingButton>
                            <CButton
                                onClick={() => {
                                    setCreateInventoryVisible(true)
                                }}
                                color={'success'}
                            >
                                <CIcon className={'mr-1'} icon={cilPlus} size="sm" />
                                Legg til
                            </CButton>
                        </CCol>
                    </CRow>
                    <CSmartTable
                        loading={isLoading}
                        columnFilter
                        columnSorter
                        clickableRows
                        onRowClick={(item) => {
                            toggleDetails(item.externalProductId)
                        }}
                        tableProps={{
                            responsive: true,
                            striped: true,
                            hover: false,
                            borderless: true,
                        }}
                        columns={[
                            {
                                key: 'listImageUrl',
                                label: '',
                                filter: false,
                                sorter: false,
                                _style: { width: '5%' },
                            },
                            {
                                key: 'sku',
                                label: 'SKU',
                                _style: { width: '10%' },
                            },
                            {
                                key: 'title',
                                label: 'Navn',
                            },
                            {
                                key: 'type',
                                label: 'Type',
                                _style: { width: '25%' },
                                filter: (values, onChange) => {
                                    const unique = Array.from(new Set(values)).sort()
                                    return (
                                        <CMultiSelect
                                            size="sm"
                                            onChange={(selected) => {
                                                const _selected = selected.map((element) => {
                                                    return element.value
                                                })
                                                onChange((item: any) => {
                                                    return Array.isArray(_selected) && _selected.length
                                                        ? _selected.includes(item)
                                                        : true
                                                })
                                            }}
                                            options={unique.map((element) => {
                                                return {
                                                    value: element,
                                                    text: typeToLabel(element),
                                                }
                                            })}
                                        />
                                    )
                                },
                            },
                            {
                                key: 'link',
                                label: '',
                                _style: { width: '10%' },
                                filter: false,
                                sorter: false,
                            },
                        ]}
                        itemsPerPage={50}
                        items={inventory}
                        scopedColumns={{
                            listImageUrl: (item: ProductAvailability) => (
                                <td>
                                    {!item.listImageUrl && (
                                        <CIcon size={'sm'} icon={cilImage} className="text-danger" />
                                    )}
                                    {item.listImageUrl && (
                                        <img
                                            src={item.listImageUrl}
                                            alt={item.title}
                                            width={24}
                                            height={24}
                                            style={{ borderRadius: '50%' }}
                                        />
                                    )}
                                </td>
                            ),
                            type: (item: ProductAvailability) => {
                                const label = typeToLabel(item.type)
                                let color
                                if (item.type == 'STOCK') {
                                    color = 'success'
                                } else {
                                    color = 'info'
                                }
                                return (
                                    <td>
                                        <CBadge color={color}>{label}</CBadge>
                                    </td>
                                )
                            },
                            link: (item: ProductAvailability) => {
                                return (
                                    <td>
                                        <CButton
                                            as="a"
                                            href={`https://uiportal-staging.bitlogwms.com/#/shipments/stock-code-details/${item.sku}`}
                                            target={'_blank'}
                                            variant={'ghost'}
                                            size="sm"
                                            width={'50%'}
                                        >
                                            Åpne i Bitlog
                                        </CButton>
                                    </td>
                                )
                            },
                            details: (item: any) => {
                                return (
                                    <CCollapse visible={details.includes(item.externalProductId)}>
                                        <CCardBody className={'p-3'}>
                                            <Availabilities item={item} regions={enabledRegions ?? []} />
                                            <InventoryHistory id={item.id} />
                                        </CCardBody>
                                        <div
                                            className={'p-0 w-100'}
                                            style={{ height: '1px', background: 'rgb(216, 219, 224)' }}
                                        />
                                    </CCollapse>
                                )
                            },
                        }}
                    />
                </CCardBody>
            </Card>
        </Page>
    )
}

function typeToLabel(type: any) {
    switch (type) {
        case 'STOCK':
            return 'Lagerstyring'
        case 'PRODUCE':
            return 'Kapasitetstyring'
        default:
            return 'Inaktiv'
    }
}
