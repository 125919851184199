import { Identity } from '@schibsted/account-sdk-browser/src/identity'
import { apiUrl, clientId, loginReturnUrl, schibstedAccountEnv, sessionDomain } from '../config'

const identity = new Identity({
    clientId: `${clientId}`,
    redirectUri: `${apiUrl}/admin/auth/login-callback`,
    sessionDomain: `${sessionDomain}`,
    env: `${schibstedAccountEnv}`,
})

function base64EncodeAsJSON(object: any): string {
    const json = JSON.stringify(object)
    return btoa(json)
}

export function login(returnPath: string) {
    identity.login({
        state: base64EncodeAsJSON({
            returnUrl: `${loginReturnUrl}${returnPath}`,
        }),
        scope: 'openid email',
    })
}

export function logout() {
    localStorage.removeItem('token')
    window.location.pathname = '/'
}
