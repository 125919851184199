import { Area, ComposedChart, Line, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts'
import React from 'react'
import { useGetPriceHistoryQuery } from '../store/productApi'
import { CSpinner } from '@coreui/react-pro'
import moment from 'moment'

interface PriceHistoryChartProps {
    productId: string
}

export default function PriceHistoryChart({ productId }: PriceHistoryChartProps) {
    const { data: priceHistory, isSuccess } = useGetPriceHistoryQuery({ productId })

    if (!isSuccess) {
        return <CSpinner />
    }

    const costPrices = priceHistory.costPrices.map((pc) => ({
        timestamp: pc.timestamp,
        costPrice: pc.price,
        price: null,
    }))

    const retailPrices = priceHistory.retailPrices.map((pc) => ({
        timestamp: pc.timestamp,
        price: pc.price,
        costPrice: null,
    }))

    let data = [...costPrices, ...retailPrices]
        .sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)))
        .map((pc) => ({
            date: moment(pc.timestamp).format('DD.MM.YYYY'),
            costPrice: pc.costPrice,
            price: pc.price,
        }))

    let lastKnownCostPrice: number | null = null
    let lastKnownPrice: number | null = null

    data.forEach((item) => {
        if (item.costPrice !== null) {
            lastKnownCostPrice = item.costPrice
        } else {
            item.costPrice = lastKnownCostPrice
        }

        if (item.price !== null) {
            lastKnownPrice = item.price
        } else {
            item.price = lastKnownPrice
        }
    })

    data = data.map((item) => ({
        ...item,
        margin: (100 - ((item.costPrice ?? 0) / (item.price ?? 1)) * 100).toFixed(2),
    }))

    return (
        <ComposedChart width={500} height={300} data={data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <defs>
                <linearGradient id="colorRetailPrice" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#7CFF97" stopOpacity={0.5} />
                    <stop offset="95%" stopColor="#7CFF97" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorCostPrice" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#FF7379" stopOpacity={0.5} />
                    <stop offset="95%" stopColor="#FF7379" stopOpacity={0} />
                </linearGradient>
            </defs>
            <Area type="monotone" dataKey="price" stroke="#7CFF97" fill="url(#colorRetailPrice)" connectNulls />
            <Line type="monotone" dataKey="margin" stroke="#738DFF" strokeWidth={2} connectNulls />
            <ReferenceLine y={40} stroke="#738DFF" strokeWidth={1} strokeDasharray="5 5" />
            <Area type="monotone" dataKey="costPrice" stroke="#FF7379" fill="url(#colorCostPrice)" connectNulls />
        </ComposedChart>
    )
}
